<app-header></app-header>

<!-- main -->
<main class="m-auto revert height-main row col-12">
    <!-- Text -->
    <div id="text" class="col-sm-4 col-8">
        <h2 class="size-text text-black">Você pode doar <br> algum valor?</h2>
        <h6 class="d-none d-sm-flex me-auto mt-4 text-start">A doação pode ajudar com nossas despesas:</h6>
        <ul class="d-none d-sm-block">
            <li class="mt-5 mb-0">
                Aluguel;
            </li>
            <li class="mt-0 mb-0">
                Funcionários;
            </li>
            <li class="mt-0 mb-0">
                Água;
            </li>
            <li class="mt-0 mb-0">
                Energia;
            </li>
            <li class="mt-0 mb-0">
                Ração;
            </li>
            <li class="mt-0 mb-0">
                Medicamentos;
            </li>
            <li class="mt-0 mb-0">
                Veterinário;
            </li>
            <li class="mt-0 mb-5">
                Material de limpeza.
            </li>
        </ul>
        <h6 class="d-none d-sm-flex d-flex flex-sm-row me-auto text-start ">Precisamos de ajuda para continuar
            mantendo o abrigo e os resgatados.</h6>

    </div>

    <!-- pix -->
    <div class="col-11 col-sm-3 section-card">
        <div class="card shadow bg-secondary" style="height: 100%; width: 100%; position: static;">
            <div class="text-center card-body d-flex flex-column">

                <h2 class="mt-2 card-title text-black">PIX</h2>
                <div class="pix-size my-auto">
                    <img class="shadow mx-auto mt-2 mt-auto" src="assets/QR-code.svg" style="width: 10rem;">
                    <p class="mt-3">QR code</p>
                    <p class="mt-5 mb-3">Chave CNPJ:</p>
                    <p id="text-to-copy" class="text-black">43.584.462/0001-06</p>
                    <button [ngClass]="{'btn-outline-dark': !effect, 'btn-secondary': effect, 'text-black': effect}" id="btn-click-to-copy"
                        class="pulse-button pulse-button:hover btn btn-copy btn-outline-dark col-8 col-sm-7"
                        (click)="copyText()">{{buttonText}}</button>
                </div>

            </div>
        </div>
    </div>

    <section style="height: 100vh;" class="d-sm-none col-12">
        <h6 class="sect">A doação pode ajudar com nossas despesas:</h6>
        <ul>
            <li class="desp-help">
                Aluguel;
            </li>
            <li class="desp-help">
                Funcionários;
            </li>
            <li class="desp-help">
                Água;
            </li>
            <li class="desp-help">
                Energia;
            </li>
            <li class="desp-help">
                Ração;
            </li>
            <li class="desp-help">
                Medicamentos;
            </li>
            <li class="desp-help">
                Veterinário;
            </li>
            <li class="desp-help mb-4">
                Material de limpeza.
            </li>
        </ul>
        <h6 class="mt-5 sect ">Precisamos de ajuda para continuar mantendo o abrigo e os resgatados.</h6>
    </section>

</main>
<app-footer></app-footer>