<app-header></app-header>

<section>
    <!-- title -->
    <h2 class="col-11 col-sm-8 mx-auto ms-sm-0 mt-5 mb-4">
        Nossos animais precisam de um lar e de muito amor.<br>Você pode ajudar?
    </h2>

    <!-- cards -->
    <div class="col-12 mx-auto d-flex flex-column flex-sm-row">

        <div class="col-11 col-sm-3 mx-auto ms-sm-0 card shadow-none">
            <hr>
            <div class="card-body">
                <h5 class="font-como-ajudar card-title">Doando Ração</h5>
                <p class="card-text">Usamos 50 KG de ração por dia para alimentar os resgatados.</p>
                <p class="card-text">Se você puder doar ração para o abrigo, vai estar ajudando a alimentar vários
                    cachorros.</p>
            </div>
        </div>

        <div class="col-11 col-sm-3 mx-auto card shadow-none">
            <hr>
            <div class="card-body">
                <h5 class="font-como-ajudar card-title">Doando algum item</h5>
                <p class="card-text">A doação de qualquer dos itens abaixo ajudará bastante.</p>
                <p class="card-text">Medicamentos, Papelão, Lençol, Tecidos, Material de Limpeza, Comedouros.</p>
            </div>
        </div>

        <div class="col-11 col-sm-3 mx-auto me-sm-0 card shadow-none">
            <hr>
            <div class="card-body">
                <h5 class="font-como-ajudar card-title">Doando algum valor</h5>
                <p class="card-text">Temos despesas mensais altíssimas. Portanto, toda ajuda é muito bem-vinda.</p>
                <p class="card-text">Obrigada!</p>
            </div>
        </div>
    </div>

    <!-- button -->
    <div class="ms-3 ms-sm-0 mt-3 mb-5 mb-sm-0">
        <a style="height: 45px; width: 150px;" href="./doacao.html" class="button-size btn btn-rounded btn-dark">Quero Doar</a>
    </div>
</section>
<app-footer></app-footer>