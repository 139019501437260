<app-header></app-header>

<div style="height:auto;" class="mx-auto col-12 row bg-page">

    <div>
        <h2 class="mt-5 col-6 mx-auto">Gostou de algum pet e quer adotar?</h2>
    </div>
    <div>
        <h5 class="mt-3 col-1 mx-auto">É bem simples</h5>
    </div>
    <div class="mb-5">
        <h5 class="mt-5 col-10 mx-auto">Vejas os animais disponíveis abaixo&nbsp;<i
                class="fas fa-arrow-right-long"></i>&nbsp;Mande uma mensagem no instagram
            informando qual pet você tem interesse&nbsp;<i class="fas fa-arrow-right-long"></i>&nbsp;Mande seu Whatsapp
            e entraremos em contato </h5>
    </div>

  <!-- Filter -->
  <form class="mt-5 mb-4 row justify-content-center col-12">

    <div class="col-3">
      <select class="form-select" aria-label="Default select example" name="filterAge" [(ngModel)]="filterAge">
        <option selected disabled>Idade</option>
        <option value="">Todas</option>
        <option value="1">0 a 5 meses</option>
        <option value="2">6 meses a 1 ano</option>
        <option value="3">Acima de 1 ano</option>
      </select>
    </div>

    <div class="col-3">
      <select class="form-select" aria-label="Default select example" name="filterSex" [(ngModel)]="filterSex">
        <option selected disabled>Sexo</option>
        <option value="">Todos</option>
        <option value="MALE">Masculino</option>
        <option value="FEMALE">Feminino</option>
      </select>
    </div>

    <div class="col-3">
      <select class="form-select" aria-label="Default select example" name="filterSize" [(ngModel)]="filterSize">
        <option selected disabled>Porte</option>
        <option value="">Todos</option>
        <option value="SMALL">Pequeno</option>
        <option value="MEDIUM">Médio</option>
        <option value="BIG">Grande</option>
      </select>
    </div>

    <button class="col-1 btn btn-secondary text-dark" type="submit" (click)="getAnimals()">Filtrar</button>
    </form>

</div>

<!-- Cards -->
<section class="vh-100">
  <div class="mx-auto px-auto col-12 row justify-content-center">

    <div class="mt-4 col-2 mx-3 text-center card" *ngFor="let animal of animals">

      <img class="card-img-top h-50" src="https://dev-services.marinaadota.com.br/files/{{animal.googleDriveFileId}}.jpg"
        alt="Miniatura de pet">
      <div class="card-body">
        <h5 class="mx-auto card-title"> {{animal.name}} </h5>
        <p class="card-text"> {{animal.age}} Anos - {{ animal.sex == "MALE" ? 'Macho' : 'Fêmea' }}</p>
        <button (click)="getAnimal(animal.id)" type="button" class="btn btn-primary" data-mdb-toggle="modal"
          data-mdb-target="#exampleModal1">
          Sobre
        </button>
      </div>

    </div>

  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{animal.name}}</h5>
                <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">


                <style>
                    /* Estilo para as miniaturas de imagem */
                    .thumbnail {
                        width: 100px;
                        height: 100px;
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    /* Estilo para a imagem principal */
                    #imagemPrincipal {
                        max-width: 400px;
                        max-height: 400px;
                        transition: all 0.3s ease-in-out;
                    }
                </style>
                <div class="container">
                  <div class="row">

                    <!-- Miniaturas de imagem -->
                    <div class="col-3" *ngFor="let fileId of animal.filesId">
                      <img src="https://dev-services.marinaadota.com.br/files/{{animal.folderId}}/{{fileId}}.jpg" class="thumbnail my-2" (click)="mudarImagem(fileId)"
                        alt="Miniatura de pet">
                    </div>

                    <!-- Imagem principal que muda dinamicamente -->
                    <div class="col-9">
                      <img src="https://dev-services.marinaadota.com.br/files/{{animal.folderId}}/{{imgWay}}.jpg" id="imagemPrincipal" class="img-fluid"
                        alt="Extensão de pet">
                    </div>
                  </div>
                </div>




            </div>

            <div class="ms-5">
                <div>
                    {{animal.age}} Anos

                </div>
                <div>
                    {{animal.sex == "MALE" ? 'Macho' : 'Fêmea'}}
                </div>

                <div>
                    {{animal.breed}}
                </div>
                <div>
                  {{animal.healthInformation}}
                </div>
                <div>
                    {{animal.description}}
                </div>

            </div>


            <div class="modal-footer">
                <a id="nav-btn-instagram" type="button"
                    class="nav-text-size shadow-none btn btn-primary text-dark mx-auto me-sm-3"
                    href="https://www.instagram.com/marina.adota/">
                    Instagram
                </a>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
