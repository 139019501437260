import { Component } from '@angular/core';

@Component({
  selector: 'app-how-to-help',
  templateUrl: './how-to-help.component.html',
  styleUrls: ['./how-to-help.component.css']
})
export class HowToHelpComponent {

}
