<footer class="ft-size bg-primary text-dark text-center text-lg-start">

    <!-- Grid container -->
    <div class="container p-4">
        <!--Grid row-->
        <div class="row">
            <!--Grid column-->
            <div class="d-flex flex-column f-size col-12 col-sm-3 mb-md-0 mx-auto" >
                <a routerLink="">
                    <img class="bg-white shadow-sm rounded-3 col-lg-6 col-md-10 mb-md-0" style="width: 80%;"
                       src="assets/logo.svg" alt="Logo marina adota">
                </a>

                <p class="mt-4">
                    Somos uma empresa apaixonada pelos animais, que há mais de dez anos, realiza ações em prol
                    do
                    bem-estar e dignidade dos cães que estão em situação de vulnerabilidade ou que são vítimas
                    de
                    maus-tratos.
                </p>
                <a href="https://www.instagram.com/marina.adota/" class="insta-size footer-link" id="Instragram">
                    <i class="fa-brands fa-instagram text-dark"></i>
                </a>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-12 col-sm-4 mb-5 mb-md-0 ms-0 me-auto mx-sm-auto d-flex flex-column text-black" style="margin: 1rem;">
                <div class="ms-1 me-auto mx-sm-auto">
                    <div>
                        <h6 class="text-uppercase">Menu</h6>
                    </div>
                    <style>
                        .list-unstyled {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                        }

                        .text-uppercase {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                        }
                    </style>
                    <ul class="list-unstyled mb-0">
                        <li>
                            <a routerLink="" fragment="about-us" class="text-dark">Quem somos</a>
                        </li>
                        <li>
                            <a routerLink="" fragment="goals" class="text-dark">Nossos objetivos</a>
                        </li>
                        <li>
                            <a routerLink="" fragment="contact-us" class="text-dark">Entre em contato</a>
                        </li>
                        <li>
                            <a routerLink="/quero-ajudar" class="text-dark">Como ajudar ?</a>
                        </li>
                        <li>
                            <a routerLink="/quero-adotar" class="text-dark">Quero Adotar</a>
                        </li>

                    </ul>

                </div>

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-12 col-sm-4 mb-4 mb-md-0 ms-0 me-auto d-flex flex-column text-black" style="margin: 1rem;">
                <div class="ms-0 me-auto mx-sm-auto">
                    <div href="#">
                        <h6 class="text-uppercase">Como ajudar?</h6>
                    </div>

                    <ul class="list-unstyled mx-auto">
                        <li>
                            <a href="quero-ajudar" class="text-dark">Doando Ração</a>
                        </li>
                        <li>
                            <a href="quero-ajudar" class="text-dark">Doando algum item</a>
                        </li>
                        <li>
                            <a href="quero-doar" class="text-dark">Doando algum valor</a>
                        </li>
                    </ul>
                </div>

            </div>
            <!--Grid column-->
        </div>
    </div>
</footer>
<div class="text-center p-3 bg-primary" style="height: 2rem;">
    <a class="text-black"
        style=" font-size: 90%; height: 4%; display:flex; align-items: center; justify-content: center;"
        href="https://mdbootstrap.com/">Instituto Marina Adota © 2023</a>
</div>
<!--footer-->
