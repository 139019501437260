import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { interval, timeInterval, timeout, timer } from 'rxjs';

@Component({
  selector: 'app-how-to-donate',
  templateUrl: './how-to-donate.component.html',
  styleUrls: ['./how-to-donate.component.css']
})
export class HowToDonateComponent {
  effect: boolean = false;
  public buttonText: string = "Copiar";

  constructor(private clipboard: Clipboard) {}

  public copyText(): void {

    this.clipboard.copy("43584462000106");
    this.buttonText = 'Copiado !'
    this.effect = true;

    setTimeout(() => {
      this.buttonText = 'Copiar'
      this.effect = false;
    }, 2000);
  }

}
