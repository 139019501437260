import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('fade', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible => hidden', animate('1s')),
      transition('hidden => visible', animate('1s')),
    ]),
  ],
})
export class HomeComponent implements OnInit {

  public title: string = "";
  private titles: string[];
  private currentIndex: number;
  public isTransitionTitle: boolean = false;

  constructor(private route: ActivatedRoute) {
    this.titles = [
      "Adoção de cachorros em Salvador",
      "Cada animal tem uma história,<br>ajude a escrever um final feliz",
      "Adote, salve uma vida e ganhe um amigo fiel",
      "Adote amor, salve vidas",
      "Com amor e cuidado, transformamos a vida de animais abandonados",
      "Nossa missão é proteger e cuidar dos animais, um de cada vez"
    ];
    this.currentIndex = 0;
  }

  ngOnInit() {
    this.title = this.titles[this.currentIndex];
    setInterval(() => { this.changeTitile(); }, 6000);

    this.route.fragment.subscribe((fragment: string | null) => {
      this.scrollIntoView(fragment);
    });
  }

  private changeTitile(): void {
    this.isTransitionTitle = true;

    setTimeout(() => {
      if (this.currentIndex == (this.titles.length - 1)) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
      this.title = this.titles[this.currentIndex];
      this.isTransitionTitle = false;
    }, 1000);
  }

  private scrollIntoView(fragment: string | null): void {
    if (fragment) {
      setTimeout(() => {
        const targetElement = document.getElementById(fragment);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
    }
  }
}
