<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
  <!-- Navbar brand -->
  <a class="navbar-brand ms-2 me-auto my-auto" routerLink="/">
    <img id="logo" src="assets/logo.svg" alt="Logo" loading="lazy" />
  </a>

  <!-- Toggle button -->
  <button class="navbar-toggler my-auto" type="button" data-mdb-toggle="collapse"
    data-mdb-target="#navbarButtonsExample" aria-controls="navbarButtonsExample" aria-expanded="false"
    aria-label="Toggle navigation">
    <i class="fas fa-bars fa-xl"></i>
  </button>

  <!-- Collapsible wrapper -->
  <div class="collapse navbar-collapse bg-white navbar-collapse-container" id="navbarButtonsExample">
    <!-- Left links -->

    <div class="d-flex flex-column flex-sm-row ms-auto text-capitalize">
      <a type="button" routerLink="/quero-ajudar" class="nav-text-size text-black nav-link px-3 me-2 my-2 my-sm-auto">
        como ajudar?
      </a>
      <a type="button" routerLink="/quero-adotar" class="nav-text-size text-black nav-link px-3 me-2 my-2 my-sm-auto">
        Quero Adotar
      </a>
      <a type="button" routerLink="/quero-doar" class="nav-text-size text-black nav-link px-3 me-2 my-2 my-sm-auto">
        Quero Doar
      </a>

      <hr class="hr d-block d-sm-none bg-dark mx-auto mt-3 mb-5" style="height: 0.3rem; width: 90%;">

      <a id="nav-btn-instagram" type="button"
        class="nav-text-size shadow-none btn btn-primary btn-rounded text-dark mx-auto me-sm-3"
        href="https://www.instagram.com/marina.adota/">
        Instagram
      </a>
    </div>
  </div>
  <!-- Collapsible wrapper -->
</nav>
