import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{

  constructor(private ellement: ElementRef, private router: Router, private renderer: Renderer2) {}

  ngOnInit(): void {

    // Se estiver na página principal
    if (this.router.url === "/") {

      // Remove a sombra do menu
      this.renderer.addClass(this.ellement.nativeElement.querySelector('.shadow-sm'), 'shadow-none');
    }
  }

}
