import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Animal } from '../shared/model/animal';
import { ReturnShortAnimal } from '../shared/model/return-short-animal';

@Injectable({
  providedIn: 'root'
})
export class AnimalService {

  constructor(private httpClient:HttpClient) { }

  public getAnimals(age: string, sex: string, size: string): Observable<ReturnShortAnimal[]> {

    let params = new HttpParams();

    params = params.set("age", age);
    params = params.set("sex", sex);
    params = params.set("size", size);

   return this.httpClient.get<ReturnShortAnimal[]>(environment.API_URL + "/short", { params })

  }

  public getAnimal(id: number): Observable<Animal> {

   return this.httpClient.get<Animal>(environment.API_URL + "/show/" + id)
  }
}
