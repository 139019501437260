import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { HowToAdoptComponent } from './pages/how-to-adopt/how-to-adopt.component';
import { HowToDonateComponent } from './pages/how-to-donate/how-to-donate.component';
import { HowToHelpComponent } from './pages/how-to-help/how-to-help.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'quero-ajudar', component: HowToHelpComponent },
  { path: 'quero-adotar', component: HowToAdoptComponent },
  { path: 'quero-doar', component: HowToDonateComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
