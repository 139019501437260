import { Component, OnInit } from '@angular/core';
import { AnimalService } from 'src/app/services/animal.service';
import { Animal } from 'src/app/shared/model/animal';
import { ReturnShortAnimal } from 'src/app/shared/model/return-short-animal';
import { Sex } from 'src/app/shared/model/sex.enum';
import { Size } from 'src/app/shared/model/size.enum';

@Component({
  selector: 'app-how-to-adopt',
  templateUrl: './how-to-adopt.component.html',
  styleUrls: ['./how-to-adopt.component.css']
})
export class HowToAdoptComponent implements OnInit {

  public animals: ReturnShortAnimal[];
  public filterAge: string;
  public filterSex: string;
  public filterSize: string;
  public imgWay: String;
  public animal: Animal;

  constructor(private animalService: AnimalService) {
    this.imgWay = "";
    this.filterAge = "";
    this.filterSex = "";
    this.filterSize = "";
    this.animals = [];
    this.animal = new Animal("", 0, "", Sex.MALE, Size.BIG, [], "", "", []);
  }

  ngOnInit() {
    this.getAnimals();
  }

  getAnimals() {

    this.animalService.getAnimals(this.filterAge, this.filterSex, this.filterSize).subscribe(
      response => {
        this.animals = response;
      },
      error => {
        console.error(error);
      }
    );
  }

  public getAnimal(id: number) {
    this.animalService.getAnimal(id).subscribe(
      response => {
        this.imgWay = response.filesId[0]
        this.animal = response
      },
      error => console.error(error)
    )
  }

  public mudarImagem(novaImagem: String) {
    this.imgWay = novaImagem
  }

}
