import { Sex } from "./sex.enum";
import { Size } from "./size.enum";

export class Animal {
  name: string;
  age: number;
  breed: string;
  sex: Sex;
  size: Size;
  healthInformation: string[];
  description: string;
  folderId: string;
  filesId: string[];

  constructor(
    name: string,
    age: number,
    breed: string,
    sex: Sex,
    size: Size,
    healthInformation: string[],
    description: string,
    folderId: string,
    filesId: string[]
  ) {
    this.name = name;
    this.age = age;
    this.breed = breed;
    this.sex = sex;
    this.size = size;
    this.healthInformation = healthInformation;
    this.description = description;
    this.folderId = folderId;
    this.filesId = filesId;
  }
}
